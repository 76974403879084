import { RouteObject } from 'react-router-dom'
import { Sections } from '../../../../components/component/Sections/Sections.tsx'
import { Button } from '../../../../components/component/Button/Button.tsx'
import { slackRedirectRoute } from './SlackRedirect.tsx'
import { useCurrentOrgSafe } from '../../../../hooks/useCurrentOrgSafe.ts'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { gqlClient } from '../../../../auth'
import { graphql } from '../../../../gql'
import { Card } from '../../../../components/component/Card/Card.tsx'
import { IconPlus, IconTrash } from '@tabler/icons-react'
import {
  IntercomIntegrationStatus,
  SlackIntegrationStatus,
} from '../../../../gql/graphql.ts'
import { MoreActions } from '../../../../components/component/MoreActions/MoreActions.tsx'
import { Dropdown } from '../../../../components/component/DropdownMenu/DropdownMenu.tsx'
import { useForm } from 'react-hook-form'
import { useFormSubmitMutation } from '../../../../hooks/useFormSubmitMutation.ts'
import { InputWrapper } from '../../../../components/input/InputWrapper/InputWrapper.tsx'
import { SelectInput } from '../../../../components/input/SelectInput/SelectInput.tsx'
import { FormSubmitButtons } from '../../../../components/component/FormSubmitButtons/FormSubmitButtons.tsx'
import { Fragment, useEffect } from 'react'
import { confirmDelete } from '../../../../components/component/Modal/Modal.tsx'
import { Badge } from '../../../../components/component/Badge/Badge.tsx'
import slackIntegrationStyle from './slackIntegration.module.scss'
import { intercomRedirectRoute } from './IntercomRedirect.tsx'
import { useFlag } from 'react-tggl-client'

const Page = () => {
  const { organization } = useCurrentOrgSafe()

  const { value: showIntercomIntegration } = useFlag(
    'intercomIntegration',
    false
  )

  const { data } = useQuery({
    queryKey: ['org', organization.id, 'integrations'],
    queryFn: () =>
      gqlClient.request(
        graphql(`
          query orgIntegrations($id: ID!) {
            organization(id: $id) {
              id
              intercomIntegration {
                status
              }
              slackIntegration {
                status
                channel
                channels
                tags {
                  tag {
                    id
                    name
                    color
                  }
                  project {
                    id
                    name
                  }
                  channel
                }
              }
            }
          }
        `),
        {
          id: organization.id,
        }
      ),
  })

  const queryClient = useQueryClient()

  const { mutateAsync: removeSlackIntegration } = useMutation({
    mutationFn: () =>
      gqlClient.request(
        graphql(`
          mutation removeSlackIntegration($id: ID!) {
            organization(id: $id) {
              removeSlackIntegration
            }
          }
        `),
        { id: organization.id }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['org', organization.id, 'integrations'],
      })
    },
  })

  const { mutateAsync: removeIntercomIntegration } = useMutation({
    mutationFn: () =>
      gqlClient.request(
        graphql(`
          mutation removeIntercomIntegration($id: ID!) {
            organization(id: $id) {
              removeIntercomIntegration
            }
          }
        `),
        { id: organization.id }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['org', organization.id, 'integrations'],
      })
    },
  })

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty, isSubmitting },
  } = useForm({
    defaultValues: {
      channel: null as string | null,
      tags: {} as Record<string, string | null>,
    },
  })

  useEffect(() => {
    reset({
      channel: data?.organization?.slackIntegration?.channel ?? null,
      tags:
        data?.organization?.slackIntegration?.tags?.reduce((acc, cur) => {
          acc[cur.tag.id] = cur.channel ?? null
          return acc
        }, {} as Record<string, string | null>) ?? {},
    })
  }, [data?.organization?.slackIntegration])

  const submit = useFormSubmitMutation({
    control,
    mutation: graphql(`
      mutation setOrgSlackChannel($id: ID!, $channel: String!, $tags: JSON!) {
        organization(id: $id) {
          setSlackIntegrationChannel(channel: $channel, tags: $tags)
        }
      }
    `),
    mapVariables: (data) => ({
      id: organization.id,
      channel: data.channel as string,
      tags: data.tags,
    }),
  })

  return (
    <Sections>
      <Sections.Section title="Slack">
        <Card variant="outlined" style={{ maxWidth: 560 }}>
          {data?.organization?.slackIntegration?.status ===
            SlackIntegrationStatus.Disabled && (
            <>
              <h2>Slack integration</h2>
              <p style={{ marginBottom: 16 }}>
                Receive notifications directly into Slack when a flag is
                updated!
              </p>
              <Button
                inline
                color="primary"
                icon={IconPlus}
                to={`https://slack.com/oauth/v2/authorize?scope=channels:read,chat:write,chat:write.public&client_id=5663064117122.6360454383728&redirect_uri=${window.location.origin}/settings/integrations/slack-redirect`}
              >
                Add to Slack
              </Button>
            </>
          )}
          {(data?.organization?.slackIntegration?.status ===
            SlackIntegrationStatus.Enabled ||
            data?.organization?.slackIntegration?.status ===
              SlackIntegrationStatus.MissingChannel) && (
            <>
              <h2 style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>Slack integration</span>
                <Dropdown component={MoreActions}>
                  <Dropdown.Item
                    label="Remove integration"
                    icon={IconTrash}
                    danger
                    onClick={() =>
                      confirmDelete({
                        unit: 'integrations',
                        description: (
                          <p>
                            Are you sure you want to remove your Slack
                            integration? You will no longer receive any
                            notification on Slack.
                          </p>
                        ),
                        onDelete: () => removeSlackIntegration(),
                      })
                    }
                  />
                </Dropdown>
              </h2>
              <form onSubmit={handleSubmit(submit)}>
                <div className={slackIntegrationStyle.container}>
                  <div className={slackIntegrationStyle.tag}>
                    <div />
                    <InputWrapper
                      // @ts-ignore
                      component={SelectInput}
                      control={control}
                      name="channel"
                      label="Default channel"
                      tooltip="All flag update notifications will be published to that channel unless overridden by a tag."
                      rules={{ required: true }}
                      options={
                        data?.organization?.slackIntegration.channels?.map(
                          (channel) => ({
                            value: channel,
                            label: '#' + channel,
                          })
                        ) ?? []
                      }
                    />
                  </div>
                  {data?.organization?.slackIntegration?.tags?.map(
                    ({ tag, project }, index, arr) => (
                      <Fragment key={tag.id}>
                        {arr[index - 1]?.project.id !== project.id && (
                          <div className={slackIntegrationStyle.project}>
                            {project.name}
                          </div>
                        )}
                        <div className={slackIntegrationStyle.tag}>
                          <Badge color={tag.color} style={{ fontSize: 12 }}>
                            {tag.name}
                          </Badge>
                          <InputWrapper
                            // @ts-ignore
                            component={SelectInput}
                            control={control}
                            name={`tags.${tag.id}`}
                            options={[
                              {
                                value: null,
                                label: (
                                  <span style={{ opacity: 0.5 }}>Default</span>
                                ),
                              },
                              ...(data?.organization?.slackIntegration.channels?.map(
                                (channel) => ({
                                  value: channel,
                                  label: '#' + channel,
                                })
                              ) ?? []),
                            ]}
                          />
                        </div>
                      </Fragment>
                    )
                  )}
                </div>
                <FormSubmitButtons
                  isDirty={isDirty}
                  isSubmitting={isSubmitting}
                  reset={reset}
                />
              </form>
            </>
          )}
          {data?.organization?.slackIntegration?.status ===
            SlackIntegrationStatus.Broken && (
            <>
              <h2>Slack integration</h2>
              <p style={{ marginBottom: 16 }}>
                You slack integration is broken. Try removing it and
                re-installing it.
              </p>
              <Button
                inline
                color="error"
                icon={IconTrash}
                onClick={() =>
                  confirmDelete({
                    unit: 'integrations',
                    description: (
                      <p>
                        You are about to delete your broken Slack integration.
                        You will need to re-install it to receive notifications
                        on Slack again.
                      </p>
                    ),
                    onDelete: () => removeSlackIntegration(),
                  })
                }
              >
                Remove broken integration
              </Button>
            </>
          )}
        </Card>
      </Sections.Section>
      {showIntercomIntegration && (
        <Sections.Section title="Intercom">
          <Card variant="outlined" style={{ maxWidth: 560 }}>
            {data?.organization?.intercomIntegration?.status ===
              IntercomIntegrationStatus.Disabled && (
              <>
                <h2>Intercom integration</h2>
                <p style={{ marginBottom: 16 }}>
                  Automatically add custom attributes to your users to see which
                  flags are active for them.
                </p>
                <Button
                  inline
                  color="primary"
                  icon={IconPlus}
                  to={`https://app.intercom.com/oauth?client_id=6c38fd0d-3938-481a-967f-4f2b6485b215`}
                >
                  Add to Intercom
                </Button>
              </>
            )}
            {data?.organization?.intercomIntegration?.status ===
              IntercomIntegrationStatus.Enabled && (
              <>
                <h2
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <span>
                    Intercom integration{' '}
                    <Badge
                      color="success"
                      style={{
                        transform: 'scale(0.7)',
                        transformOrigin: 'bottom left',
                      }}
                    >
                      Active
                    </Badge>
                  </span>
                  <Dropdown component={MoreActions}>
                    <Dropdown.Item
                      label="Remove integration"
                      icon={IconTrash}
                      danger
                      onClick={() =>
                        confirmDelete({
                          unit: 'integrations',
                          description: (
                            <p>
                              Are you sure you want to remove your Intercom
                              integration? Data attributes on your users will
                              not be deleted, but there values will stop being
                              synced.
                            </p>
                          ),
                          onDelete: () => removeIntercomIntegration(),
                        })
                      }
                    />
                  </Dropdown>
                </h2>
                <p>
                  {' '}
                  To see if a given flag is enabled for a specific user directly
                  in Intercom, go to the flag's setting page and enable{' '}
                  <b>Intercom sync</b>.
                </p>
              </>
            )}
          </Card>
        </Sections.Section>
      )}
    </Sections>
  )
}

export const integrationsRoute: RouteObject = {
  path: 'integrations',
  children: [
    {
      index: true,
      element: <Page />,
    },
    slackRedirectRoute,
    intercomRedirectRoute,
  ],
}
