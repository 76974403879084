import {
  CellContext,
  Column,
  ColumnDef,
  ColumnFiltersState,
  ColumnOrderState,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  OnChangeFn,
  RowSelectionState,
  SortingState,
  useReactTable,
  VisibilityState,
} from '@tanstack/react-table'
import cx from 'classnames'
import {
  IconArrowUp,
  IconChevronDown,
  IconColumns3,
  IconEyeFilled,
  IconEyeOff,
  IconFilter,
  IconGripVertical,
  IconLoader2,
  IconPlus,
  IconX,
} from '@tabler/icons-react'
import { FC, forwardRef, ReactNode, useEffect, useMemo } from 'react'
import { TextInput } from '../../input/TextInput/TextInput.tsx'
import { Toolbar } from '../Toolbar/Toolbar.tsx'
import { Checkbox } from '../../input/Checkbox/Checkbox.tsx'
import { Button } from '../Button/Button.tsx'
import { FormattedMessage } from 'react-intl'
import { Dropdown } from '../DropdownMenu/DropdownMenu.tsx'
import { customIconButton, MoreActions } from '../MoreActions/MoreActions.tsx'
import searchImage from '../../../assets/empty-state/file-not-found.svg'
import { Link } from 'react-router-dom'
import { Skeleton } from '../Skeleton/Skeleton.tsx'
import { useSilentLoader } from '../../../hooks/useSilentLoader.tsx'
import searchUser from '../../../assets/empty-state/search-user.svg'
import { EmptyStateImage } from '../../layout/EmptyStateImage/EmptyStateImage.tsx'
import { Unit } from '../../../intl/types.ts'
import { Tooltip } from '../Tooltip/Tooltip.tsx'
import { sanitize } from '../../../utils/string.ts'
import { IconButton } from '../IconButton/IconButton.tsx'
import { Reorder } from 'framer-motion'
import { useSearchParam } from '../../../hooks/useSearchParam.ts'
import {
  getFacetedUniqueValues,
  getIdOrValue,
} from './getFacetedUniqueValues.tsx'
import { deserializeFilters, serializeFilters } from './filterSerializer.ts'

export type TableProps<TRow extends { id: string }> = {
  data: TRow[]
  loading?: boolean
  fetching?: boolean
  loadingRows?: number
  columns: ColumnDef<TRow>[]
  defaultSorting?: SortingState
  defaultPageSize?: number
  rowSelection?: RowSelectionState
  onRowSelectionChange?: OnChangeFn<RowSelectionState>
  unit?: Unit
  moreActions?: (data: CellContext<TRow, unknown>) => ReactNode
  createButtonText?: string
  onCreate?: () => void
  rowTo?: (row: TRow) => string | null
  noToolbar?: boolean
  emptyState?: ReactNode
  selectionToolbar?: ReactNode
  noSelectionToolbar?: ReactNode
  noCustomColumns?: boolean
  defaultColumnVisibility?: Record<string, boolean>
  defaultColumnFilters?: ColumnFiltersState
  localState?: boolean
}

const getStringValue = (value: any): string => {
  if (typeof value === 'string') {
    return sanitize(value)
  }

  if (Array.isArray(value)) {
    return value.map(getStringValue).join('-')
  }

  if (typeof value === 'object' && value !== null) {
    return Object.entries(value)
      .map(([k, v]) =>
        k === 'id' || k === '__typename' || k === 'color'
          ? ''
          : getStringValue(v)
      )
      .join('-')
  }

  return ''
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value: string) => {
  const content = getStringValue(row.original)

  return value.trim()
    ? value
        .split(' ')
        .map((s) => s.trim())
        .filter(Boolean)
        .every((s) => content.includes(sanitize(s)))
    : true
}

const FilterValues: FC<{ column: Column<any>; removeWhenEmpty?: boolean }> = ({
  column,
  removeWhenEmpty,
}) => {
  const values = new Set(column.getFilterValue() as string[])

  return (
    <>
      <h3>{flexRender(column.columnDef.header, {} as any)}</h3>
      {[...column.getFacetedUniqueValues().entries()].map(([value, count]) => {
        const key = getIdOrValue(value)
        values.delete(key)
        return (
          <div
            className="table-filter-value"
            key={key}
            onClick={() =>
              column.setFilterValue((oldValue: any[]) => {
                if (!oldValue) {
                  return [key]
                }
                if (oldValue.includes(key)) {
                  if (oldValue.length === 1 && removeWhenEmpty) {
                    return undefined
                  }

                  return oldValue.filter((k) => k !== key)
                }

                return [...oldValue, key]
              })
            }
          >
            <Checkbox
              style={{ pointerEvents: 'none' }}
              value={
                (column.getFilterValue() as string[] | undefined)?.includes(
                  key
                ) ?? false
              }
              onChange={() => null}
            />
            {value !== null && value !== undefined && value !== ''
              ? column.columnDef.meta?.renderFilter?.(value) ?? value
              : '<empty>'}
            <span className="table-filter-counter">{count}</span>
          </div>
        )
      })}
      {values.size ? (
        <div
          className="table-filter-value"
          onClick={() =>
            column.setFilterValue((oldValue: any[]) => {
              if (oldValue.length === values.size && removeWhenEmpty) {
                return undefined
              }

              return oldValue.filter((k) => !values.has(k))
            })
          }
        >
          <Checkbox
            style={{ pointerEvents: 'none' }}
            value={true}
            onChange={() => null}
          />
          +{values.size} more
          <span className="table-filter-counter">0</span>
        </div>
      ) : null}
      {column.getIsFiltered() && (
        <div style={{ margin: '8px 4px 0 4px' }}>
          <Button
            variant="light"
            onClick={() =>
              column.setFilterValue(() => (removeWhenEmpty ? undefined : []))
            }
          >
            Clear
          </Button>
        </div>
      )}
    </>
  )
}

const Filter: FC<{
  column: Column<any>
  value: any
}> = ({ column, value }) => {
  if (!column) {
    return
  }

  return (
    <Dropdown
      component={forwardRef<HTMLDivElement>((props, ref) => {
        return (
          <div {...props} className="filter" ref={ref}>
            <Tooltip>
              <Tooltip.Trigger
                component={IconX}
                size={14}
                onClick={() => {
                  column.setFilterValue(undefined)
                }}
              />
              <Tooltip.Content>Remove filter</Tooltip.Content>
            </Tooltip>
            {flexRender(column.columnDef.header, {} as any)}
            <hr />
            <span className="value">
              {value.length}{' '}
              <FormattedMessage
                id={`unit.values`}
                values={{
                  count: value.length,
                }}
              />
            </span>
            <IconChevronDown size={14} />
          </div>
        )
      })}
    >
      <FilterValues column={column} />
    </Dropdown>
  )
}

const serializeSortState = (sorting: SortingState) =>
  sorting.map(({ id, desc }) => `${desc ? '-' : ''}${id}`).join(',')

const deserializeSortState = (sort: string): SortingState =>
  sort.split(',').map((s) => ({
    id: s.replace(/^-/, ''),
    desc: s.startsWith('-'),
  }))

export const Table = <TRow extends { id: string }>({
  columns,
  data,
  loading = false,
  fetching = false,
  loadingRows = 4,
  defaultSorting,
  defaultPageSize = 25,
  rowSelection,
  onRowSelectionChange,
  unit = 'items',
  moreActions,
  onCreate,
  rowTo,
  noToolbar = false,
  emptyState,
  createButtonText = 'New',
  selectionToolbar,
  noSelectionToolbar,
  defaultColumnVisibility = {},
  defaultColumnFilters = [],
  noCustomColumns = false,
  localState = false,
}: TableProps<TRow>) => {
  const rowSelectionEnabled = Boolean(rowSelection)
  const silentLoader = useSilentLoader(loading)

  const enhancedColumns = useMemo<ColumnDef<TRow>[]>(() => {
    const output: ColumnDef<TRow>[] = []

    if (rowSelectionEnabled) {
      output.push({
        id: 'selection',
        header: ({ table }) => {
          return table.getPrePaginationRowModel().rows.length === 0 ? null : (
            <Checkbox
              value={table.getIsAllPageRowsSelected()}
              indeterminate={table.getIsSomePageRowsSelected()}
              onChange={(checked) =>
                table.getToggleAllPageRowsSelectedHandler()({
                  target: { checked },
                })
              }
            />
          )
        },
        cell: ({ row }) => {
          return (
            <Checkbox
              value={row.getIsSelected()}
              onChange={row.getToggleSelectedHandler()}
            />
          )
        },
        minSize: 36,
        enableHiding: false,
        enableGlobalFilter: false,
        meta: {
          flex: '0 0 36px',
          compact: true,
          noLink: true,
          loadingCell: () => null,
        },
      })
    }

    output.push(
      // @ts-ignore
      ...columns.map((column) => ({
        filterFn: 'equals',
        sortingFn: 'alphanumeric',
        ...column,
        enableGlobalFilter: !column.enableHiding,
      }))
    )

    if (moreActions) {
      output.push({
        id: 'actions',
        meta: {
          flex: '0 0 44px',
          compact: true,
          noLink: true,
          loadingCell: () => null,
        },
        cell: (data) => (
          <Dropdown component={MoreActions}>{moreActions(data)}</Dropdown>
        ),
        enableHiding: false,
        enableGlobalFilter: false,
        minSize: 44,
      })
    }
    return output
  }, [columns, moreActions, rowSelectionEnabled])

  const [columnOrder, setColumnOrder] = useSearchParam<ColumnOrderState>('o', {
    defaultValue: columns.map((column) => column.id as string),
    format: (value) => value.join(' '),
    parse: (value) => value.split(' '),
    localState,
  })
  const [sorting, setSorting] = useSearchParam<SortingState>('s', {
    defaultValue: defaultSorting ?? [],
    format: serializeSortState,
    parse: deserializeSortState,
    localState,
  })
  const [columnVisibility, setColumnVisibility] =
    useSearchParam<VisibilityState>('v', {
      defaultValue: {
        ...defaultColumnVisibility,
        ...columns.reduce((acc, cur) => {
          if (cur.meta?.hidden) {
            acc[cur.id ?? ''] = false
          }
          return acc
        }, {} as Record<string, boolean>),
      },
      format: (value) =>
        Object.entries(value)
          .reduce((acc, cur) => {
            if (
              cur[1] === false &&
              !columns.some(
                (column) => column.id === cur[0] && column.meta?.hidden
              )
            ) {
              acc.push(cur[0])
            }
            return acc
          }, [] as string[])
          .sort()
          .join(' '),
      parse: (value) =>
        value
          .split(' ')
          .filter(Boolean)
          .reduce(
            (acc, cur) => {
              acc[cur] = false
              return acc
            },
            columns.reduce((acc, cur) => {
              if (cur.meta?.hidden) {
                acc[cur.id ?? ''] = false
              }
              return acc
            }, {} as Record<string, boolean>)
          ),
      localState,
    })
  const [search, setSearch] = useSearchParam<string>('q', {
    defaultValue: '',
    format: (value) => value,
    parse: (value) => value,
    localState,
  })
  const [pageIndex, setPageIndex] = useSearchParam<number>('p', {
    defaultValue: 0,
    format: (value) => String(value),
    parse: (value) =>
      isNaN(Number(value)) ? 0 : Math.max(0, Math.round(Number(value))),
    localState,
  })
  const [columnFilters, setColumnFilters] = useSearchParam<ColumnFiltersState>(
    'f',
    {
      defaultValue: defaultColumnFilters,
      format: serializeFilters,
      parse: deserializeFilters,
      localState,
    }
  )

  const table = useReactTable({
    data,
    getCoreRowModel: getCoreRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    globalFilterFn: fuzzyFilter,
    filterFns: {
      equals: (row, columnId, filterValue) => {
        const colValue = getIdOrValue(row.getValue(columnId))

        if (colValue === filterValue) {
          return true
        }

        if (
          (typeof colValue === 'string' ||
            typeof colValue === 'boolean' ||
            typeof colValue === 'number') &&
          Array.isArray(filterValue) &&
          (filterValue.length === 0 || filterValue.includes(colValue))
        ) {
          return true
        }

        if (
          typeof filterValue === 'string' &&
          Array.isArray(colValue) &&
          colValue.some((value) => getIdOrValue(value) === filterValue)
        ) {
          return true
        }

        if (
          Array.isArray(filterValue) &&
          Array.isArray(colValue) &&
          (filterValue.length === 0 ||
            colValue.some((value) => filterValue.includes(getIdOrValue(value))))
        ) {
          return true
        }

        return false
      },
    },
    columns: enhancedColumns,
    enableSortingRemoval: false,
    initialState: {
      globalFilter: search,
    },
    state: {
      columnPinning: {
        left: ['selection'],
        right: ['actions'],
      },
      columnFilters,
      columnOrder,
      sorting,
      columnVisibility,
      pagination: {
        pageIndex,
        pageSize: defaultPageSize,
      },
      rowSelection,
    },
    getRowId: (row) => row.id,
    enableRowSelection: rowSelectionEnabled,
    onRowSelectionChange,
    onPaginationChange: (updater) => {
      const newState =
        typeof updater === 'function'
          ? updater(table.getState().pagination)
          : updater
      setPageIndex(newState.pageIndex)
    },
    onSortingChange: (updater) => {
      const newState =
        typeof updater === 'function'
          ? updater(table.getState().sorting)
          : updater
      setSorting(newState)
    },
    onColumnVisibilityChange: (updater) => {
      const newState =
        typeof updater === 'function'
          ? updater(table.getState().columnVisibility)
          : updater
      setColumnVisibility(newState)
    },
    onColumnFiltersChange: (updater) => {
      const newState =
        typeof updater === 'function'
          ? updater(table.getState().columnFilters)
          : updater
      setColumnFilters(newState)
    },
    defaultColumn: {
      minSize: 150,
      maxSize: 0,
    },
  })

  const tableState = table.getState()

  // Debounce search into url
  useEffect(() => {
    const timeout = setTimeout(() => setSearch(tableState.globalFilter), 500)

    return () => {
      clearTimeout(timeout)
    }
  }, [setSearch, tableState.globalFilter])

  const numberRowsSelected = Object.keys(rowSelection ?? {}).length

  if (silentLoader) {
    return null
  }

  if (!loading && table.getPreFilteredRowModel().rows.length === 0) {
    return (
      emptyState ?? (
        <EmptyStateImage
          title={
            <>
              You have no{' '}
              <FormattedMessage
                id={`unit.${unit}`}
                values={{
                  count: 0,
                }}
              />{' '}
              yet
            </>
          }
          ctaOnClick={onCreate}
          ctaIcon={IconPlus}
          cta="Create"
        />
      )
    )
  }

  return (
    <>
      {!noToolbar && (
        <Toolbar>
          <div className="filters">
            {tableState.columnFilters.map((filterState) => (
              <Filter
                key={filterState.id}
                value={filterState.value}
                column={table.getColumn(filterState.id)!}
              />
            ))}
            {tableState.columnFilters.length <
              table.getAllColumns().filter((column) => column.getCanFilter())
                .length && (
              <Dropdown
                component={forwardRef<HTMLDivElement>((props, ref) => {
                  return (
                    <div {...props} className="filter add-filters" ref={ref}>
                      <IconPlus size={14} /> Add filter
                    </div>
                  )
                })}
              >
                {table
                  .getAllColumns()
                  .filter(
                    (column) => column.getCanFilter() && !column.getIsFiltered()
                  )
                  .map((column) => (
                    <Dropdown.Item
                      key={column.id}
                      label={
                        flexRender(column.columnDef.header, {} as any) as string
                      }
                      onClick={() => column.setFilterValue([])}
                    />
                  ))}
              </Dropdown>
            )}
          </div>
          {numberRowsSelected > 0 && (
            <>
              <span>
                <b>{numberRowsSelected}</b>{' '}
                <FormattedMessage
                  id={`unit.${unit}`}
                  values={{ count: numberRowsSelected }}
                />{' '}
                selected
              </span>
              {table.getIsAllPageRowsSelected() &&
              !table.getIsAllRowsSelected() ? (
                <Button
                  variant="light"
                  onClick={() => table.toggleAllRowsSelected(true)}
                >
                  select all {data.length}{' '}
                  <FormattedMessage
                    id={`unit.${unit}`}
                    values={{ count: data.length }}
                  />
                </Button>
              ) : (
                <Button
                  variant="light"
                  onClick={() => table.setRowSelection({})}
                >
                  clear
                </Button>
              )}
            </>
          )}
          <div style={{ flex: 1 }} />
          {!loading && fetching && (
            <IconLoader2
              className="spinner"
              size={20}
              style={{ color: '#969696' }}
            />
          )}
          {!noCustomColumns && (
            <Dropdown component={customIconButton(IconColumns3)}>
              <h3>Columns</h3>
              <Reorder.Group
                className="table-columns"
                axis="y"
                values={table.getState().columnOrder}
                onReorder={setColumnOrder}
              >
                {table.getState().columnOrder.map((columnId) => {
                  const column = table.getColumn(columnId)

                  if (!column || column.columnDef.meta?.hidden) {
                    return null
                  }

                  const header = column.columnDef.header

                  return (
                    <Reorder.Item key={columnId} value={columnId}>
                      <IconGripVertical size={12} className="grab" />
                      <span className="title">
                        {typeof header === 'string' ? header : null}
                      </span>
                      <IconButton
                        icon={
                          table.getState().columnVisibility[column.id] === false
                            ? IconEyeOff
                            : IconEyeFilled
                        }
                        disabled={!column.getCanHide()}
                        onClick={column.getToggleVisibilityHandler()}
                      />
                    </Reorder.Item>
                  )
                })}
              </Reorder.Group>
            </Dropdown>
          )}
          <TextInput
            search
            value={tableState.globalFilter}
            onChange={(search) =>
              table.setState((state) => ({
                ...state,
                globalFilter: search,
              }))
            }
          />
          {(!rowSelection || Object.keys(rowSelection).length === 0) &&
            noSelectionToolbar}
          {onCreate &&
            (!rowSelection || Object.keys(rowSelection).length === 0) && (
              <Button color="primary" onClick={onCreate} icon={IconPlus}>
                {createButtonText}
              </Button>
            )}
          {rowSelection &&
            Object.keys(rowSelection).length > 0 &&
            selectionToolbar}
        </Toolbar>
      )}
      <div className="table-container">
        <table className="table">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className={cx(
                      header.column.getCanSort() && 'sortable',
                      header.column.columnDef.meta?.compact && 'compact',
                      header.column.getIsFiltered() && 'filtered'
                    )}
                    style={{
                      flex: header.column.columnDef.meta?.flex ?? '1 1 150px',
                      minWidth: header.column.columnDef.minSize || undefined,
                      maxWidth: header.column.columnDef.maxSize || undefined,
                    }}
                    onClick={() => {
                      if (header.column.getCanSort()) {
                        header.column.toggleSorting()
                      }
                    }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    {header.column.getCanSort() && (
                      <IconArrowUp
                        size={16}
                        className={cx(
                          'sorting-indicator',
                          header.column.getIsSorted() || 'not-sorted',
                          'next-' +
                            (header.column.getNextSortingOrder() ||
                              'not-sorted')
                        )}
                      />
                    )}
                    {header.column.getCanFilter() && (
                      <>
                        <div className="filter-spacer" />
                        <Dropdown
                          component={customIconButton(IconFilter)}
                          placement="bottom"
                        >
                          <FilterValues
                            column={header.column}
                            removeWhenEmpty
                          />
                        </Dropdown>
                      </>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {loading &&
              !data.length &&
              new Array(loadingRows).fill(0).map((_, i) => (
                <tr key={i}>
                  {table.getAllColumns().map((column) => (
                    <td
                      key={column.id}
                      className={cx(
                        column.columnDef.meta?.compact && 'compact'
                      )}
                      style={{
                        flex: column.columnDef.meta?.flex ?? '1 1 150px',
                        minWidth: column.columnDef.minSize || undefined,
                        maxWidth: column.columnDef.maxSize || undefined,
                      }}
                    >
                      {(
                        column.columnDef.meta?.loadingCell ??
                        (() => <Skeleton />)
                      )()}
                    </td>
                  ))}
                </tr>
              ))}
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    className={cx(
                      cell.column.columnDef.meta?.compact && 'compact'
                    )}
                    style={{
                      flex: cell.column.columnDef.meta?.flex ?? '1 1 150px',
                      minWidth: cell.column.columnDef.minSize || undefined,
                      maxWidth: cell.column.columnDef.maxSize || undefined,
                    }}
                  >
                    {rowTo &&
                    !cell.column.columnDef.meta?.noLink &&
                    rowTo(cell.row.original) ? (
                      <Link
                        to={rowTo(cell.row.original) as string}
                        className="cell-link"
                        tabIndex={-1}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Link>
                    ) : (
                      flexRender(cell.column.columnDef.cell, cell.getContext())
                    )}
                  </td>
                ))}
              </tr>
            ))}
            {tableState.globalFilter &&
              table.getPrePaginationRowModel().rows.length === 0 &&
              table.getPreFilteredRowModel().rows.length > 0 && (
                <tr className="table-empty-state">
                  <td>
                    <img
                      className="illustration"
                      src={unit === 'members' ? searchUser : searchImage}
                      alt="no results"
                    />
                    No{' '}
                    <FormattedMessage
                      id={`unit.${unit}`}
                      values={{
                        count: 0,
                      }}
                    />{' '}
                    matching your search
                    <Button
                      onClick={() => table.resetGlobalFilter()}
                      color="primary"
                    >
                      Clear
                    </Button>
                  </td>
                </tr>
              )}
            {!tableState.globalFilter &&
              table.getPrePaginationRowModel().rows.length === 0 &&
              table.getPreFilteredRowModel().rows.length > 0 && (
                <tr className="table-empty-state">
                  <td>
                    <img
                      className="illustration"
                      src={unit === 'members' ? searchUser : searchImage}
                      alt="no results"
                    />
                    No{' '}
                    <FormattedMessage
                      id={`unit.${unit}`}
                      values={{
                        count: 0,
                      }}
                    />{' '}
                    matching your filters, try adjusting them
                  </td>
                </tr>
              )}
          </tbody>
        </table>
      </div>
      <Toolbar className="table-footer">
        <span style={{ flex: 1 }}>
          <b>{table.getPrePaginationRowModel().rows.length}</b>{' '}
          <FormattedMessage
            id={`unit.${unit}`}
            values={{
              count: table.getPrePaginationRowModel().rows.length,
            }}
          />
        </span>
        {table.getPageCount() > 1 && (
          <>
            <Button
              disabled={!table.getCanPreviousPage()}
              onClick={table.previousPage}
            >
              Previous
            </Button>
            <Button disabled={!table.getCanNextPage()} onClick={table.nextPage}>
              Next
            </Button>
          </>
        )}
      </Toolbar>
    </>
  )
}
